.hero {
  padding-top: 4rem;
  height: calc(100vh - 6rem);
  // if you change this image, update the image credit in the footer!
  background: url(/images/hero-bg.png) no-repeat;
  background-size: cover;

  &__content {
    z-index: 5;
    position: relative;
    display: flex;
    align-items: end;
    padding-bottom: 0rem;
  }

  &__logo {
    position: absolute;
    top: 3.6rem;
    left: 0;
    z-index: 2;
  }
}
