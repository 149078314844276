$accordionPadding: 5rem 4rem;
$accordionPaddingMobile: 2rem 2rem 0.1rem 2rem;
$detailPadding: 1.6rem 3rem 1.6rem 1.6rem;
$accordionBR: 3rem;
$accordionBRMobile: 2rem;
$accordionDetailBR: 1.3rem;

.accordion {
  background: color(pink);
  padding: $accordionPaddingMobile;
  border-radius: 3rem;

  @include breakpoint(medium) {
    padding: $accordionPadding;
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    list-style: none;
    padding: 0;
    margin: 0;
    background: color(dark);
    color: color(lightText);
    margin-bottom: $accordionBRMobile;
    border-radius: $accordionDetailBR;
    line-height: 2.4rem;

    @include breakpoint(medium) {
      margin-bottom: $accordionBR;
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }

  &__title {

    button {
      padding: $detailPadding;
      height: 100%;
      width: 100%;
      background: none;
      border: none;
      border-bottom: 3px solid color(leightGrey);
      color: inherit;
      border-radius: 1.3rem 1.3rem 0 0;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
    }
  }

  &__btn {
    transition: all .3s;

    &:hover {
      background-color: #303d3e;
    }
  }

  &__btn-icons {
    // display: flex;
    // align-items: center;
    position: relative;
    pointer-events: none;
    height: 2rem;
    width: 2rem;

    .icons--show {
      transition: all .3s;
      position: absolute;
      height: 2rem;
      width: 2rem;
      opacity: 0;
      top: 0;
      left: 0;
    }
    .icon--plus {
      opacity: 1;
    }
  }

  &__detail {
    max-height: 0;
    overflow: hidden;
    padding: $detailPadding;
    transition: all .6s;
  }
}

.accordion__item.accordion__item--open {
  .accordion__detail {
    max-height: 60rem;
  }

  .accordion__title {
    .icons--show {
      transform: rotate(180deg);
    }
    .icon--plus {
      opacity: 0;
    }
    .icon--min {
      opacity: 1;
    }
  }
}
