.tab-block {
  padding: 6rem 0;
  position: relative;
  overflow: hidden;

  &__section-wrapper {
    position: relative;
    z-index: 3;
  }

  &__affiliates-copy-area {
    @include breakpoint(medium) {
      max-width: 75%;
    }
  }

  &__tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 7rem;

    @include breakpoint(medium) {
      display: flex;
    }

    li {
      margin: 0 2.7rem 0 0;
      transition: all .3s;
      border-bottom: 7px solid rgba(0, 0, 0, 0);

      &:hover {
        opacity: 0.8;
      }

      &.is-active {
        border-bottom: 7px solid color(burnLight);
      }
    }
  }

  &__affiliates-ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1.3rem;
    background: color(affiliates);
    margin-bottom: 4rem;
    margin-top: -2rem;
    flex-wrap: wrap;

    @include breakpoint(small) {
      flex-direction: row;
    }

    @include breakpoint(medium) {
      max-width: 75%;
    }
  }

  &__affiliates-li {
    text-align: center;
    align-items: center;
    display: flex;
    margin: 0.5rem;
    border: solid 2px transparent;
    transition: all .3s;

    border-radius: 0.8rem;
    background-color: #fee2c0;
    padding: 0.5rem 1rem;

    &:hover {
      border-color: #755425;
    }
    
    h5 {
      font-size: 1.6rem;
      margin: 0;
      margin-left: 1rem;
    }
  }

  &__affiliates-li svg {
    height: 2rem;
  }

  &__btn {
    font-family: $oswald;
    font-size: 4rem;
    color: color(leightGrey);
    text-transform: uppercase;
    margin: 0;
    background: none;
    border: none;
    margin-bottom: 1rem;
    cursor: pointer;

    @include breakpoint(medium) {
      font-size: 6rem;
    }
  }

  &__tab-content {
    transition: all .3s;
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__content {
    @include breakpoint(medium) {
      padding-left: 12rem;
    }
    
    .tab-block__btn {
      color: color(burnLight);
      margin-bottom: 1rem;
    }

    .cms {
      color: color(burn);
    }

    .is-active {
      .tab-block__btn {
        color: color(burn);
      }
    }
  }

  &__bk {
    transition: all .8s;
    height: 0;
    width: 0;
    z-index: 2;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--r {
      background-color: #FFD6A5;
    }
    &--g {
      background-color: rgb(250, 228, 168);
    }
    &--b {
      background-color: #efdfb4;
    }

    &--selected {
      z-index: 2;
      height: calc(100vw + 80rem);
      width: calc(100vw + 80rem);

    }
  }
}
