.scroll-reveal:not(.accordion-section) {
  transition: all .5s;
  margin-top: 6rem;
  opacity: 0;

  &.scroll-reveal--revealed {
    margin-top: 0;
    opacity: 1;
  }
}

html {
  overflow-x: hidden;
}
