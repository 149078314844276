.form-section {
  padding: 6rem 0;
  background: color(formBg);

  .cms {
    color: color(textRed);
    a {
      color: color(formLinkText);
    }
  }

  .form {
    input.address-contact { display: none; }

    input,
    textarea {
      width: 100%;
      border: none;
      font-size: 2.8rem;
      @extend .font-poppins; 
      color: color(textRed);
    }

    input {
      overflow: hidden;
    }

    &__input-group {
      position: relative;
      margin-bottom: 2rem;

      label {
        transition: all .3s;
        color: color(textRed);
        position: absolute;
        @extend .font-oswald;
        text-transform: uppercase;
        font-size: 3rem;
        left: 1.1rem;
        top: 1.2rem;
      }

      &.is-active {
        label {
          font-size: 1.2rem;
          top: 0.5rem;
        }
      }

      input,
      textarea {
        padding: 2rem 11px 11px 11px;
      }

      &:hover {
        label {
          color: color(formLabelHover);
        }
      }
    }
  }
}
