// Use this file to import font's other than iconfonts. You can use fontsquirrel to create a font-face file
// lato-Bold / lato-Regular / Lato-Light / Lato-Black
//$primary-font: 'Merriweather', 'Georgia', serif;

// font weight 300 400 700 900

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700');

$poppins: 'Poppins', 'Arial', sans-serif;
$oswald: 'Oswald', 'Arial', sans-serif;

html {
  font-family: $poppins;
  // font-display: swap !important;
}

.heading1 {
  font-family: $oswald;
  font-size: 5rem;
  color: inherit;
  text-transform: uppercase;
  font-weight: 400;

  @include breakpoint(medium) {
    font-size: 7rem;
  }
}

.heading2 {
  font-family: $oswald;
  font-size: 6rem;
  color: color(leightGrey);
  text-transform: uppercase;
  margin: 0;

  @include breakpoint(medium) {
    font-size: 4rem;
  }
}

.font-poppins {
  font-family: $poppins;
}

.font-oswald {
  font-family: $oswald;
}
