.cms {
  color: inherit;
  font-weight: 500;

  .heading1 {
    margin-top: 0;
    font-weight: 400;
  }

  a {
    color: inherit;
  }

  a:hover {
    opacity: .8;
  }
  ul li {
    margin-bottom: 1rem;
    line-height: 24px;
  }
  p {
    line-height: 24px;
  }
}
