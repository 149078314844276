.cta {
  position: relative;
  display: block;
  width: 100%;

  h1 {
    margin-top: 0;
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 4.6rem;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 4.6rem;
    margin-bottom: 8.6rem;
  }

  &__bg {
    background: color(ctaBackground);
    position: absolute;
    height: 100%;
    opacity: 0.94;
    width: 100%;
    bottom: 0;
    top: 0;
  }
  
  &__inner {
    position: relative;
    width: 100%;
    color: color(lightText);
    z-index: 5;
    padding: 2rem;
    
    @include breakpoint(medium) {
      padding: 2.3rem 3rem 4.5rem;
    }

    display: flex;
  }

  &__inner-copy {
    @include breakpoint(medium) {
      width: 70%;
    }
  }

  &__inner-flr {
    width: 30%;
    position: relative;
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__poly {
    position: absolute;
    transition: all 1.2s;
    top: 0;

    &--1 {
      top: 5%;
      right: 19%;
    }

    &--2 {
      top: 28%;
      right: -30%;
    }

    &--3 {
      top: 80%;
      right: 5%;
    }
  }

  &__info {
    max-width: 80%;
    font-size: 2rem;
    line-height: 2.8rem;

    p {
      padding-right: 60px;
    }
    &:after {
      content: '';
      display: block;
      border-bottom: solid 7px #969696;
    }
  }

  .btn {
    margin-bottom: 2.3rem;
  }
}
