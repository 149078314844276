.h-full {
  height: 100%;
}

.desktop-only {
  display: none !important;

  @include breakpoint(medium) {
    display: block !important;
    &.grid__cell {
      display: inline-block !important;
    }
  }
}