.gutter {
  background: #142122;
  padding: 4rem 0 6rem 0;

  @include breakpoint(medium) {
    padding: 6rem;
  }
  
  .cms,
  .cms a {
    color: color(white);
  }

}