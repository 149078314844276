// usage:
// wrap a section in a div called 'reveal-section'
// Use JS to add 'reveal-section--revealed' on viewport entry

.reveal-section {
  position: relative;  

  &:after {
    content:'';
    position: absolute;
    display: block;
    height: 98%;
    width: 98%;
    top: calc(-60px + 1%);
    top: 0;
    left: 1%;
    background-color: rgb(216, 216, 216);
    border: dotted 3px rgb(116, 116, 116);
    border-radius: 3rem;
    // transform: all .6s;
  }

  .accordion {
    position: relative;
    z-index: 5;
    transition: all .6s cubic-bezier(0, 0, 0, 1.31);
    top: 60px;
  }

  &--revealed {
    .accordion {
      top: 0;
    }
  }
}
