// Declare any avalanche settings overrides here
.grid {
  // width: 100%;

  &__example {
    background-color: rgb(232, 241, 124);
    padding: 20px 20px 0;
  }

  .grid__cell {
    font-size: 1.6rem;
  }

  &--extend-middle {
    @include breakpoint(medium) {
      display: flex !important;
      align-items: center !important;
    }
  }
}

.grid.grid-example .grid__cell {
  .box {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;

    background-color: aqua;
  }

  &:nth-child(odd) {
    .box {
      background-color: aquamarine;
    }
  }
}

// .grid__cell:nth-of-type(even)[data-f~="1/2--lap-and-up"] {
//   @include breakpoint(lap-and-up) {
//     padding-left: 24px;
//   }
// }


$av-gutter: 4rem;

$av-widths: (
  2,
  3,
  4,
  1,
  6,
  8,
  12
);
// $av-enable-grid-cell-center: true;
// $av-enable-grid-flush: true;
// $av-enable-grid-center: true;

$av-breakpoints: (
  'lap-and-up': 'screen and (min-width: 801px)',
  'desk': 'screen and (min-width: 1025px)',
  'xl': 'screen and (min-width: 1400px)'
);
