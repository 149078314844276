.accordion-section {
  background: color(sectionGrey);
  padding: 6rem 0;
  position: relative;
  background-color: color(backgroundPink);

  .section__wrapper {
    position: relative;
    z-index: 3;
  }

  &:after {
    content:'';
    display: block;
    position: absolute;
    height: 30%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: color(basicGrey);
    z-index: 2;
  }
}
