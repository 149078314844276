.title-block {
  padding: 6rem 0 0;
  position: relative;

  &__section-wrapper {
    padding-top: 6rem;
  }

  &__content {
    @include breakpoint(medium) {
      padding-left: 12rem;
    }
  }

  color: color(textRed);
}

.title-block__divider {
  height: 20px;
  background: color(backgroundPink);
  width: 75%;

  &--bottom {
    margin-top: 6rem;
  }
}
