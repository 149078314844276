// Colour Palette
//
// Primary and secondary colours are defined in settings/_colour-palette.scss. We use actual colour names to define the variables. This prevents ambiguation when naming e.g. $grey, $lightGrey, $lighterGrey.
//

// call : color: color(white);

$colorPalette: (
  dark: #142122,
  ctaBackground: #11221C,
  white: #fff,
  lightText: #E6DEDE,
  leightGrey: #969696,
  sectionGrey: #EBEBEB,
  hoverBorder: #FFD6A5,
  pink: #ECC4E6,
  basicGrey: #F4F4F4,
  backgroundPink: #eedede,
  burn: #755425,
  burnLight: #CC9D61,
  textRed: #8b4e4e,
  formBg: #FF9B9B,
  formLinkText: #4c3939,
  affiliates: #ebc9a0,
  formLabelHover: #bb7d7d,
);

@each $color in $colorPalette {
  .color-#{nth($color, 1)} {
    color: nth($color, 2);
  }
  .fill-#{nth($color, 1)} {
    background-color: nth($color, 2);
  }
}

@function color($key) {
  @if map-has-key($colorPalette, $key) {
    @return map-get($colorPalette, $key);
  }

  @warn "Unknown `#{$key}` in $colorPalette.";
  @return null;
}

.bg-basic-grey {
  background-color: color(basicGrey);
}

.bg-hover-border {
  background-color: color(hoverBorder);
}
