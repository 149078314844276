.nav {
  background-color: color(dark);
  color: color(white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;

  &__logo-wrap {
    transition: all .3s;
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }

    svg {
      height: 3rem;
      width: auto;
      
    }
  }

  &__main-content {
    min-height: 4rem;;
    display: flex;
    justify-content: space-between;
  }

  &__link-area {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    li, button {
      padding: 0;
      margin: 0;
      background: none;
      border: none;
    }

    li {
      margin-right: 2rem;
    }

    button {
      // background-color: gold;
      color: color(white);
      transition: all .3s;
      font-size: 1.8rem;
      cursor: pointer;

      &:hover {
        color: color(hoverBorder);
      }
    }
  }

  &__button {
    background: none;
    border: none;
  }
}
