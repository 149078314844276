$siteWith : 1260px;

html {
  box-sizing: border-box;
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 1.6rem; // also set on grid__cell
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  height: auto;
  max-width: 100%;
  border: 0;
}

.section {
  &__wrapper {
    padding: 0 20px;
    &--h-full {
      height: 100%;
    }

    &--contain {
      max-width: $siteWith;
      margin: 0 auto;
    }
  }
}
