.btn {
  font-family: $oswald;
  text-transform: uppercase;
  font-display: swap !important;
  font-size: 2rem;
  padding: 1.6rem 7rem;
  border: solid 3px color(leightGrey);
  border-radius: 8px;
  transition: all .3s;
  // normal
  background-color: color(ctaBackground);
  color: color(lightText);
  cursor: pointer;

  &.light {
    background: color(textRed);
    border-color: color(white);
    color: color(white);
  }

  &:hover {
    border-color: color(hoverBorder);
    color: color(hoverBorder);
  }
}
